<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<AccountNav></AccountNav>
			</div>
			<div class="admin-col whole_000 whole_fff flex_bd">
				<div class="acc_box api_box accu_box">
					<div class="container">
						<div class="acc-hd flex flex-ac flex-zBetween">
							<div class="acc-tit">{{$t('meun4_1')}}</div>
						</div>
						<div class="acc-in flex flex-ac flex-zBetween">
							<div class="acc-name flex flex-ac"><span class="acc-name-text">{{$t('totalAssets')}}</span>
								<p>${{totalUSDT}}</p>
								<div class="acc-yue">≈{{currentInfo.symbol}}{{totalCny*currentInfo.rate}}</div>
							</div>
							<div class="acc-in-r acc-btn flex flex-ac">
								<el-button  type="primary" class="btn btn-mb0" @click="dialogVisible = true">
									{{$t('transfer')}}
								</el-button>
								<el-input v-model.trim="searchInput" @input="search" :placeholder="$t('placeholder')"
									prefix-icon="el-icon-search"></el-input>
							</div>
						</div>
						<!-- 资金划转 -->
						<el-dialog :visible.sync="dialogVisible" width="490px" custom-class="custom-dialog">
							<div slot="title" class="dialog-title"><b>{{$t('transfer')}}-USDT</b></div>
							<div class="dialog-content">
								<div class="transfer-form">
									<div class="transfer-form-item">
										<img src="../../assets/photo/web/form.png" alt="">
										<div class="transfer-form-item__label" v-if="transferDirection == 1">
											{{$t('czth2')}}
										</div>
										<div class="transfer-form-item__label" v-else>{{$t('czth3')}}</div>
										<div class="flex_bd">
											<el-select v-model="walletOne" :placeholder="$t('meun10_1')+'(USDT)'"
												@change="select" style="width: 100%;">
												<el-option value="usdt">{{$t("meun10_1")}} (USDT)</el-option>
											</el-select>
										</div>
									</div>
									<div class="transfer-form-icon">
										<div class="left-icon" v-if="transferDirection == 1" @click="changeTo(2)">
											<el-tooltip class="item" effect="dark" :content="$t('clickchange')"
												placement="bottom">
												<el-button><i class="el-icon-bottom"></i></el-button>
											</el-tooltip>
										</div>
										<div class="left-icon" v-if="transferDirection == 2" @click="changeTo(1)">
											<el-tooltip class="item" effect="dark" :content="$t('clickchange')"
												placement="bottom">
												<el-button><i class="el-icon-top"></i></el-button>
											</el-tooltip>
										</div>
										<!-- <div class="right-icon"><i class="el-icon-sort"></i></div> -->
									</div>
									<div class="transfer-form-item">
										<img src="../../assets/photo/web/to.png" alt="">
										<div class="transfer-form-item__label" v-if="transferDirection == 1">
											{{$t('czth3')}}
										</div>
										<div class="transfer-form-item__label" v-else>{{$t('czth2')}}</div>
										<div class="flex_bd">
											<el-select v-model="walletTwo" :placeholder="$t('uplaceholder')"
												@change="select2" style="width: 100%;">
												<el-option v-for="item in tableData" :value="item.contractCoin.symbol"
													:key="item.id">
													{{ item.contractCoin.symbol }} {{$t("udTxt8")}}
												</el-option>
											</el-select>
										</div>
									</div>
								</div>
								<div class="quota-cell">
									<div class="quota-cell-item">{{$t('udTxt5')}}：{{assetsWallet.balance.toFixed(2)}}
									</div>
									<div class="quota-cell-item">{{$t('udTxt5')}}：{{swapWallet.usdtBalance.toFixed(2)}}
									</div>
								</div>
								<div class="transfer-cell">
									<div class="transfer-cell__hd">{{$t('udTxt6')}}</div>
									<div class="transfer-cell__bd">
										<div class="transfer-input flex_bd">
											<el-input type="number" v-model="amount" :min="0"></el-input>
											<!-- <el-input-number v-model="amount" :placeholder="$t('udTxt6')" :min="0"
												:controls="false"></el-input-number> -->
										</div>
										<el-button type="text" @click="tranfer">{{$t('udTxt7')}}</el-button>
									</div>
								</div>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-row :gutter="14">
									<el-col :span="12">
										<el-button @click="closeDia">{{$t('close')}}</el-button>
									</el-col>
									<el-col :span="12">
										<el-button  type="primary" @click="save">{{$t('hztxt')}}
										</el-button>
									</el-col>
								</el-row>
							</div>
						</el-dialog>
						<div class="acc-table exte-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column :label="$t('bith1')">
									<template slot-scope="scope">
										<div>
											{{scope.row.contractCoin.symbol}} {{$t('udTxt8')}}
										</div>
									</template>
								</el-table-column>
<!--								<el-table-column prop="account" :label="$t('bith2')">-->
<!--									<template slot-scope="scope">-->
<!--										<div>-->
<!--											<span v-if="scope.row.account == 0">-->
<!--												{{scope.row.account}}-->
<!--											</span>-->
<!--											<span v-else>{{ scope.row.account.toFixed(4) }}</span>-->
<!--										</div>-->
<!--									</template>-->
<!--								</el-table-column>-->
<!--								<el-table-column prop="usdtTotalProfitAndLoss" :label="$t('bith3')">-->
<!--									<template slot-scope="scope">-->
<!--										<div>-->
<!--											<span v-if="scope.row.usdtTotalProfitAndLoss == 0">-->
<!--												{{scope.row.usdtTotalProfitAndLoss}}-->
<!--											</span>-->
<!--											<span v-else>{{ scope.row.usdtTotalProfitAndLoss.toFixed(4) }}</span>-->
<!--										</div>-->
<!--									</template>-->
<!--								</el-table-column>-->
								<el-table-column prop="usdtBalance" :label="$t('bith4')">
									<template slot-scope="scope">
										<div>
											<span v-if="scope.row.usdtBalance == 0">
												{{scope.row.usdtBalance}}
											</span>
											<span v-else>{{ scope.row.usdtBalance.toFixed(4) }}</span>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="usdtBuyPrincipalAmount" :label="$t('bith5')">
									<template slot-scope="scope">
										<div>
											<span v-if="scope.row.usdtBuyPrincipalAmount == 0">
												{{scope.row.usdtBuyPrincipalAmount}}
											</span>
											<span v-else>{{ scope.row.usdtBuyPrincipalAmount.toFixed(4) }}</span>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="usdtFrozenBalance" :label="$t('bith6')">
									<template slot-scope="scope">
										<div>
											<span v-if="scope.row.usdtFrozenBalance == 0">
												{{scope.row.usdtFrozenBalance}}
											</span>
											<span v-else>{{ scope.row.usdtFrozenBalance.toFixed(4) }}</span>
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		walletList,
		swapTrans
	} from '@/api/api/swap'
	import {
		assetWallet
	} from '@/api/api/user'
	import AccountNav from '@/components/AccountNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			AccountNav,
			Foot
		},
		data() {
			return {
				transferDirection: 1,
				searchInput: '',
				tableData: [],
				all: [],
				totalUSDT: '',
				totalCny: '',
				dialogVisible: false,
				walletOne: '',
				walletTwo: '',
				assetsWallet: {
					balance: 0
				},
				swapWallet: {
					usdtBalance: 0
				},
				amount: 0,
			};
		},
		computed: {
			currentInfo: function() {
				return this.$store.getters.current
			}
		},
		mounted() {
			this.getList()
			this.getWallet()
		},
		methods: {
			getList() {
				walletList().then(res => {
					if (res.code == 0) {
						var arr = []
						res.data.forEach(item => {
							item.account = item.usdtBalance + item.usdtFrozenBalance + item
								.usdtBuyPrincipalAmount + item.usdtSellPrincipalAmount + item
								.usdtTotalProfitAndLoss
							return arr.push(item)
						})


						let usdtTotal = 0;
						let cnyTotal = 0;
						for (let i = 0; i < arr.length; i++) {
							usdtTotal += arr[i].usdtBalance + arr[i].usdtFrozenBalance + arr[i]
								.usdtBuyPrincipalAmount + arr[i].usdtSellPrincipalAmount + arr[i]
								.usdtTotalProfitAndLoss;
						}
						for (let k = 0; k < arr.length; k++) {
							var sumV = arr[k].usdtBalance + arr[k].usdtFrozenBalance + arr[k]
								.usdtBuyPrincipalAmount + arr[k].usdtSellPrincipalAmount + arr[k]
								.usdtTotalProfitAndLoss;
							cnyTotal += sumV * arr[k].contractCoin.usdtRate;
						}


						this.totalUSDT = usdtTotal.toFixed(2)
						this.totalCny = cnyTotal.toFixed(2)
						this.tableData = arr
						this.all = arr
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			changeTo(val) {
				this.transferDirection = val;
				this.amount = 0;
			},
			getWallet() {
				assetWallet({
					symbol: 'usdt',
				}).then(res => {
					if (res.code == 0) {
						this.assetsWallet = res.data
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			search(value) {
				let keyWord = value
				this.tableData = this.all.filter(item => {
					return item.contractCoin.symbol.indexOf(keyWord) != -1 && item.contractCoin.symbol
						.localeCompare(keyWord) != -1
				})
			},
			select2(value) {
				let arr = this.all.filter(item => {
					return item.contractCoin.symbol == value
				})
				this.walletTwo = value + ' ' + this.$t('udTxt8')
				this.swapWallet = arr[0]
			},
			select() {
				this.walletOne = this.$t('meun10_1') + '(USDT)'
			},
			save() {
				console.log(this.amount)
				if (this.walletOne == '' || this.walletTwo == '') {
					this.$message(this.$t('swappleaseselectwallet'));
				} else if (this.amount == undefined) {
					this.$message(this.$t('swappleaseinputamount'));
				} else if (this.amount == 0) {
					this.$message(this.$t('swappleaseinputamount'));
				} else {
					let parms = {
						amount: this.amount,
						unit: 'USDT',
						from: this.transferDirection == 1 ? 0 : 1,
						to: this.transferDirection == 1 ? 1 : 0,
						fromWalletId: this.transferDirection == 1 ? this.assetsWallet.id : this.swapWallet.id,
						toWalletId: this.transferDirection == 1 ? this.swapWallet.id : this.assetsWallet.id,
					}
					swapTrans(
						parms
					).then(res => {
						if (res.code == 0) {
							this.getList();
							this.getWallet();
							this.amount = 0
							this.walletTwo = ''
							this.walletOne = ''
						} else {
							this.$message.error(res.message);
						}
					})

					this.dialogVisible = false
				}

			},
			tranfer() {
        if(this.transferDirection==1){
          //现货
          this.amount = this.assetsWallet.balance
        }else {
          this.amount = this.swapWallet.usdtBalance
        }
			},
			closeDia() {
				this.amount = 0
				this.walletTwo = ''
				this.dialogVisible = false
			}
		}
	}
</script>
